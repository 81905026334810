
import { GET, DELETE } from '../request'

export const getLeadsCRM = ({ mes, centro })=> GET('/api/core/marketing/feedback/list/CRM', { mes, centro })

export const getLeadList = (data)=> GET('/api/core/marketing/feedback/list', data)

export const getLeadsAnalysis = (filter)=> GET('/api/core/marketing/feedback/list/analysis', filter)

export const getLeadsIncomingPhone = ({ mes })=> GET('/api/core/marketing/feedback/list/incoming-phone', { mes })

export const deleteLead = (id)=> DELETE('/api/core/marketing/feedback', { id })
