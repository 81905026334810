import { TextField } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import css from './FechaSelector.module.css'

const FechaSelector = ({ 
  className='', 
  label, 
  value, 
  disabled=false, 
  disablePast=false, 
  disableFuture=false, 
  onChange 
})=> (
  <div className={`${css.wrapper} ${className}`}>
    <MobileDatePicker
      variant='standard'
      label={label}
      inputFormat="dd/MM/yyyy"
      mask="__/__/____"
      value={value}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      closeOnSelect
      onChange={onChange}
      renderInput={(params) => <TextField {...params} />}
    />
  </div>
)

export default FechaSelector