import { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { isBlank } from "../../utils/string"
import DocumentSelector from "../../components/form/DocumentSelector/DocumentSelector"
import MesRecienteSelector from "../../components/form/MesRecienteSelector/MesRecienteSelector"
import { monthToString } from "../../utils/date"
import css from './ModalCrearDocumento.module.css'

const ModalCrearDocumento = ({ open, onSubmit, onClose }) => {

  const [documento, setDocumento] = useState(null)
  const [mes, setMes] = useState(monthToString(new Date()))

  const isSubmitEnabled = !isBlank(documento)

  const handleSubmit = () => {
    const details = { url: documento.value }
    if (documento.monthSelection) details.mes = mes
    onSubmit(details)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Generar nuevo documento</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Estás a punto de solicitar un documento CSV con datos de <strong>mundo</strong>estudiante.
          Es un proceso que puede tardar varios minutos en completarse y estar disponible para descargar. 
          Puedes cerrar esta pestaña y comprobarlo más tarde.
        </DialogContentText>
        <DocumentSelector
          className={css.input}
          name='documento'
          label='Documento'
          value={documento}
          onChange={e=> setDocumento(e.target.value)}
        />
        {documento && documento.monthSelection && (
          <MesRecienteSelector
            className={css.mes}
            name='mes'
            label='Mes'
            value={mes}
            onChange={e=> setMes(e.target.value)}
            forwardOptions={0}
            backwardOptions={13}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Crear</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearDocumento