import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import EjercicioSelector from '../../../../components/form/EjercicioSelector/EjercicioSelector'
import { CENTROS } from '../../../../utils/academia'
import { format, formatForExport } from '../../../../utils/math'
import { downloadCSVFile, getCSVContent } from '../../../../utils/csv'
import MesSelector from '../../../../components/form/MesSelector/MesSelector'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import InformationTable from '../../../../components/Table/InformationTable'
import TableHeader from '../../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { getLeadList } from '../../../../utils/api/leads'
import { getLeadDetails } from '../../../../utils/api/peticionesWeb'
import css from './CaptacionMes.module.css'

const STARTING_VALUE = { leads: 0, pruebas: 0, captados: 0, abiertos: 0, novalidos: 0 }

const processDatos = ({ leads, peticiones })=> {
  const centros = [];
  // Fill with initial values
  CENTROS.forEach(m=> {
    centros.push({ name: `${m}`, value: {...STARTING_VALUE} })
    centros.push({ name: `${m} (Web)`, value: {...STARTING_VALUE} })
    centros.push({ name: `${m} (SEM)`, value: {...STARTING_VALUE} })
  })
  // Process the leads
  leads
    .forEach(lead=> {
      const centro = centros.find(m=> m.name === lead.centro)
      centro.value.leads++
      if (lead.prueba) centro.value.pruebas++
      if (!lead.estado.startsWith('CLIENTE') && (lead.estado !== 'LEAD_NO_VALIDO')) centro.value.abiertos++
      if (lead.estado === 'CLIENTE_CAPTADO') centro.value.captados++
      if (lead.estado === 'LEAD_NO_VALIDO') centro.value.novalidos++
    })
  // Process the web requests
  peticiones
    .forEach(lead=> {
      const centro_web = centros.find(m=> m.name === `${lead.centro} (Web)`)
      const centro_sem = centros.find(m=> m.name === `${lead.centro} (SEM)`)
      const isSem = lead.origenSEM
      centro_web.value.leads++
      if (isSem) centro_sem.value.leads++
      if (lead.prueba) centro_web.value.pruebas++
      if (isSem && lead.prueba) centro_sem.value.pruebas++
      if (!lead.estado.startsWith('CLIENTE') && (lead.estado !== 'LEAD_NO_VALIDO')) centro_web.value.abiertos++
      if (isSem && !lead.estado.startsWith('CLIENTE') && (lead.estado !== 'LEAD_NO_VALIDO')) centro_sem.value.abiertos++
      if (lead.estado === 'CLIENTE_CAPTADO') centro_web.value.captados++
      if (isSem && lead.estado === 'CLIENTE_CAPTADO') centro_sem.value.captados++
      if (lead.estado === 'LEAD_NO_VALIDO') centro_web.value.novalidos++
      if (isSem && lead.estado === 'LEAD_NO_VALIDO') centro_sem.value.novalidos++
    })

  // Generate totals
  const totals_sem = {...STARTING_VALUE}
  const totals_web = {...STARTING_VALUE}
  const total_leads = {...STARTING_VALUE}

  const combine = (totals, mes)=> {
    totals.leads += mes.leads
    totals.pruebas += mes.pruebas
    totals.captados += mes.captados
    totals.abiertos += mes.abiertos
    totals.novalidos += mes.novalidos
  }

  centros.forEach(mes=> {
    if (mes.name.endsWith("(Web)")) combine(totals_web, mes.value)
    else if (mes.name.endsWith("(SEM)")) combine(totals_sem, mes.value)
    else combine(total_leads, mes.value)
  })
  
  return [
    ...centros, 
    { name: 'Total', value: total_leads },
    { name: 'Total (Web)', value: totals_web },
    { name: 'Total (SEM)', value: totals_sem }
  ]
}

const CaptacionMes = ()=> {

  const notification = useNotification()

  const [mes, setMes] = useState((new Date()).getMonth()+1)
  const [ejercicio, setEjercicio] = useState((new Date()).getFullYear())

  const { isLoading: isLoadingLeads, data: feedbackList=[] } = useQuery({
    queryKey: ['feedback', 'list', mes, ejercicio], 
    queryFn: ()=> {
      const fecha_inicio = `${mes}/${ejercicio}`
      const fecha_fin = `${(mes%12)+1}/${mes < 12 ? ejercicio : (ejercicio+1)}`
      return getLeadList({ 
        fecha_inicio, 
        fecha_fin, 
        "centros[]": ['TODOS'] 
      })
        .then(datos=> datos)
        .catch(err=> {
          notification.error({ title: 'Error al recuperar los leads', content: err })
          return []
        })
    }
  })

  const { isLoading: isLoadingPeticiones, data: peticionList=[] } = useQuery({
    queryKey: ['peticion_web', 'list', 'feedback_details', mes, ejercicio, 'TODOS'], 
    queryFn: ()=> {
      const fecha_inicio = `${mes}/${ejercicio}`
      const fecha_fin = `${(mes%12)+1}/${mes < 12 ? ejercicio : (ejercicio+1)}`
      return getLeadDetails({
        fecha_inicio, 
        fecha_fin, 
        "centros[]": ['TODOS']
      })
        .then(datos=> datos)
        .catch(err=> {
          notification.error({ title: 'Error al recuperar las peticiones web', content: err })
          return []
        })
    }
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Centro', 'Leads', 'No válidos', 'Pruebas', 'Abiertos', 'Captados', 'Lead → Prueba', 'Prueba → Alumno', 'Lead → Alumno' ],
      data: processDatos({ leads: feedbackList, peticiones: peticionList }),
      transform: c=> ([
        c.name,
        `${c.value.leads}`,
        `${c.value.novalidos}`,
        `${c.value.pruebas}`,
        `${c.value.captados}`,
        `${c.value.abiertos}`,
        `${formatForExport(100 * c.value.pruebas / (c.value.leads - c.value.novalidos))}%`,
        `${formatForExport(100 * c.value.captados / c.value.pruebas)}%`,
        `${formatForExport(100 * c.value.captados / (c.value.leads - c.value.novalidos))}%`
      ])
    })
    downloadCSVFile(content, 'captacion-mes')
  }

  return (
    <PageLoading isLoading={isLoadingLeads || isLoadingPeticiones}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <MesSelector
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
            <EjercicioSelector
              className={css.year}
              name='ejercicio'
              label='Ejercicio'
              value={ejercicio}
              onChange={e=> setEjercicio(e.target.value)}
            />
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Resultados'
        showSearch={false}
      />
      <InformationTable
        details={[
          { title: 'Centro', key: 'centro', align: 'left' },
          { title: 'Leads', key: 'leads', sortDisabled: true, align: 'center' },
          { title: 'No válidos', key: 'no_validos', sortDisabled: true, align: 'center' },
          { title: 'Pruebas', key: 'pruebas', sortDisabled: true, align: 'center' },
          { title: 'Abiertos', key: 'abiertos', sortDisabled: true, align: 'center' },
          { title: 'Captados', key: 'captados', sortDisabled: true, align: 'center' },
          { title: 'Lead → Prueba', key: 'conversion_lead_prueba', sortDisabled: true, align: 'center' },
          { title: 'Prueba → Alumno', key: 'conversion_prueba_alumno', sortDisabled: true, align: 'center' },
          { title: 'Lead → Alumno', key: 'conversion_lead_alumno', sortDisabled: true, align: 'center' },
        ]}
        data={processDatos({ leads: feedbackList, peticiones: peticionList })
          .map(centro=> ({
            centro: centro.name.startsWith("Total") ? <strong>{centro.name}</strong> : centro.name,
            leads: centro.value.leads,
            no_validos: <span className={css.error}>{centro.value.novalidos}</span>,
            pruebas: centro.value.pruebas,
            captados: <span className={css.success}>{centro.value.captados}</span>,
            abiertos: centro.value.abiertos,
            conversion_lead_prueba: `${format(100 * centro.value.pruebas / (centro.value.leads - centro.value.novalidos))}%`,
            conversion_prueba_alumno: `${format(100 * centro.value.captados / centro.value.pruebas)}%`,
            conversion_lead_alumno: `${format(100 * centro.value.captados / (centro.value.leads - centro.value.novalidos))}%`
          }))
        }
      />
    </PageLoading>
  )

}

export default CaptacionMes