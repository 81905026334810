import { Fab, IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import ModalCrearAsignatura from '../../../modals/ModalCrearAsignatura/ModalCrearAsignatura'
import ModalEditarAsignatura from '../../../modals/ModalEditarAsignatura/ModalEditarAsignatura'
import { createAsignatura, getAsignaturas, updateAsignatura } from '../../../utils/api/asignaturas'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { textFilter } from '../../../utils/table'
import css from './Asignaturas.module.css'

const StatusIcon = ({ isOk })=> (
  <i 
    className={`${css.icon} material-icons`}
    data-status={isOk ? 'valid' : 'invalid'}
  >
    {isOk ? 'check' : 'close'}
  </i>
)

const Asignaturas = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [search, setSearch] = useState('')

  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [asignaturaEdit, setAsignaturaEdit] = useState(null)

  const { isLoading, data: asignaturaList=[] } = useQuery({
    queryKey: ['asignatura', 'list'], 
    queryFn: ()=> getAsignaturas()
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asignaturas', content: err })
        return []
      })
  })

  const { isFetching: isCreatingAsignatura, mutate: crearAsignatura } = useMutation({
    mutationFn: createAsignatura,
    onSuccess: ()=> {
      setCreateModalOpen(false)
      notification.success({ title: 'Asignatura creada', content: 'La asignatura ha sido creada correctamente' })
      queryClient.invalidateQueries(['asignatura', 'list'])
    },
    onError: err=> notification.error({ title: 'Error al crear la asignatura', content: err }),
  })

  const { isFetching: isUpdatingAsignatura, mutate: actualizarAsignatura} = useMutation({
    mutationFn: updateAsignatura,
    onSuccess: ()=> {
      setEditModalOpen(false)
      notification.success({ title: 'Asignatura actualizada', content: 'La asignatura ha sido actualizada correctamente' })
      queryClient.invalidateQueries(['asignatura', 'list'])
    },
    onError: err=> notification.error({ title: 'Error al actualizar la asignatura', content: err }),
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Tipo', 'Nivel', 'Estado'],
      data: asignaturaList,
      transform: a=> ([
        a.nombre,
        a.tipo,
        a.nivel,
        a.subnivel,
        a.activa ? 'Activa' : 'Inactiva',
      ])
    })
    downloadCSVFile(content, 'asignaturas')
  }

  const handleCrearAsignatura = (asignatura)=> {
    if (isCreatingAsignatura) return
    crearAsignatura(asignatura)
  }

  const handleEditarAsignatura = (asignatura)=> {
    if (isUpdatingAsignatura) return
    actualizarAsignatura(asignatura)
  }

  const handleOpenAsignaturaEdit = (asignatura)=> {
    setEditModalOpen(true)
    setAsignaturaEdit(asignatura)
  }


  const filterSearch = asignatura=> {
    return textFilter({ 
      object: asignatura, 
      fields: ['titulo', 'descripcion', 'centro'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Asignaturas'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'nombre', align: 'left' },
          { title: 'Tipo', key: 'tipo', align: 'left' },
          { title: 'Nivel', key: 'nivel', align: 'left' },
          { title: 'Estado', key: 'estado', sortDisabled: true, align: 'center' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' }
        ]}
        data={asignaturaList
          .filter(filterSearch)
          .map(asignatura=> ({
            nombre: asignatura.nombre,
            tipo: asignatura.tipo,
            nivel: asignatura.subnivel ? `${asignatura.nivel} - ${asignatura.subnivel}` : asignatura.nivel,
            estado: <StatusIcon isOk={asignatura.activa} />,
            opciones: (
              <IconButton 
                size='small' 
                color='secondary'
                onClick={()=> handleOpenAsignaturaEdit(asignatura)}
              >
                <i className='material-icons'>edit</i>
              </IconButton>
            )
          }))
        }
      />
      <Fab
        className={css.nuevo}
        disabled={createAsignatura.status === 'loading'}
        onClick={()=> setCreateModalOpen(true)}
        size="medium"
        color="primary" 
        aria-label="add"
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalCrearAsignatura
        open={isCreateModalOpen}
        onSubmit={handleCrearAsignatura}
        onClose={()=> setCreateModalOpen(false)}
      />
      <ModalEditarAsignatura
        asignatura={asignaturaEdit}
        open={isEditModalOpen}
        onSubmit={handleEditarAsignatura}
        onClose={()=> setEditModalOpen(false)}
      />
    </PageLoading>
  )

}

export default Asignaturas