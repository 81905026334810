import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { getUsuarioList } from '../../../utils/api/usuarios'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import { textFilter } from '../../../utils/table'
import css from './Administracion.module.css'

const StatusIcon = ({ isOk })=> (
  <i 
    className={`${css.icon} material-icons`}
    data-status={isOk ? 'valid' : 'invalid'}
  >
    {isOk ? 'check' : 'close'}
  </i>
)

const Administracion = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: adminList=[] } = useQuery({
    queryKey: ['usuario', 'list'], 
    queryFn: ()=> getUsuarioList()
      .then(datos=> datos.sort((a, b)=> {
        if (a.cuentaBloqueada && !b.cuentaBloqueada) return 1
        if (!a.cuentaBloqueada && b.cuentaBloqueada) return -1
        return a.nombre.localeCompare(b.nombre)
      }))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los usuarios', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Email', 'Rol', 'Estado'],
      data: adminList,
      transform: a=> ([
        a.nombre,
        a.email,
        a.rol,
        a.cuentaBloqueada ? 'Bloqueado' : 'Activo',
      ])
    })
    downloadCSVFile(content, 'usuarios')
  }

  const filterSearch = usuario=> {
    return textFilter({ 
      object: usuario, 
      fields: ['nombre', 'email', 'rol'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Personal de administración'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'nombre', align: 'left' },
          { title: 'Email', key: 'email', align: 'left' },
          { title: 'Rol', key: 'rol', align: 'left' },
          { title: 'Acceso', key: 'acceso', sortDisabled: true, align: 'center' },
        ]}
        data={adminList
          .filter(filterSearch)
          .map(admin=> ({
            nombre: admin.nombre,
            email: admin.email,
            rol: admin.rol,
            acceso: <StatusIcon isOk={!admin.cuentaBloqueada} />,
          }))
        }
      />
    </PageLoading>
  )
}

export default Administracion