import { useEffect, useState } from "react"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material"
import { isBlank } from "../../utils/string"
import TipoAsignaturaSelector from "../../components/form/TipoAsignaturaSelector/TipoAsignaturaSelector"
import NivelAsignaturaSelector from "../../components/form/NivelAsignaturaSelector/NivelAsignaturaSelector"
import SubnivelAsignaturaSelector from "../../components/form/SubnivelAsignaturaSelector/SubnivelAsignaturaSelector"
import css from './ModalEditarAsignatura.module.css'

const ModalEditarAsignatura = ({ asignatura, open, onSubmit, onClose }) => {

  const [asignaturaUpdate, setAsignaturaUpdate] = useState({
    id: '',
    nombre: '',
    nombreCompleto: '',
    tipo: '',
    nivel: '',
    subnivel: '',
    activa: true,
  })
  
  useEffect(()=> {
    if (open) return
    setAsignaturaUpdate({
      id: '',
      nombre: '',
      nombreCompleto: '',
      tipo: '',
      nivel: '',
      subnivel: '',
      activa: true,
    })
  } , [open])

  useEffect(()=> {
    if (!asignatura || !asignatura.id) return
    if (asignaturaUpdate.id !== asignatura.id) {
      setAsignaturaUpdate({
        id: asignatura.id,
        nombre: asignatura.nombre,
        nombreCompleto: asignatura.nombreCompleto,
        tipo: asignatura.tipo,
        nivel: asignatura.nivel,
        subnivel: asignatura.subnivel,
        activa: asignatura.activa,
      })
    }
  }, [asignaturaUpdate.id, asignatura])

  const isSubmitEnabled = (
    !isBlank(asignaturaUpdate.id) &&
    !isBlank(asignaturaUpdate.nombre) && 
    !isBlank(asignaturaUpdate.nombreCompleto) &&
    !isBlank(asignaturaUpdate.tipo) &&
    !isBlank(asignaturaUpdate.nivel)
  )

  const handleSubmit = () => {
    onSubmit({ 
      id: asignaturaUpdate.id,
      nombre: asignaturaUpdate.nombre,
			nombre_completo: asignaturaUpdate.nombreCompleto,
			descripcion: asignaturaUpdate.nombreCompleto,
			tipo: asignaturaUpdate.tipo,
			nivel: asignaturaUpdate.nivel,
      subnivel: asignaturaUpdate.subnivel,
      activa: true,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar asignatura</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes modificar los datos de la asignatura con el siguiente formulario.
        </DialogContentText>
        <TextField
          className={css.input}
          label="Nombre"
          value={asignaturaUpdate.nombre}
          onChange={e=> setAsignaturaUpdate({ ...asignaturaUpdate, nombre: e.target.value })}
          variant='standard'
        />
        <TextField
          className={css.input}
          label="Nombre Completo"
          value={asignaturaUpdate.nombreCompleto}
          onChange={e=> setAsignaturaUpdate({ ...asignaturaUpdate, nombre_completo: e.target.value })}
          variant='standard'
        />
        <TipoAsignaturaSelector
          className={css.input} 
          name='tipo'
          label='Tipo asignatura'
          value={asignaturaUpdate.tipo}
          onChange={e=> setAsignaturaUpdate({ ...asignaturaUpdate, tipo: e.target.value })}
        />
        <NivelAsignaturaSelector
          className={css.input}
          name='nivel'
          label='Nivel asignatura'
          value={asignaturaUpdate.nivel}
          onChange={e=> setAsignaturaUpdate({ ...asignaturaUpdate, nivel: e.target.value })}
        />
        <SubnivelAsignaturaSelector
          className={css.input}
          name='subnivel'
          label='Subnivel asignatura'
          value={asignaturaUpdate.subnivel}
          onChange={e=> setAsignaturaUpdate({ ...asignaturaUpdate, subnivel: e.target.value })}
        />
        <FormControlLabel
          control={
            <Checkbox 
              checked={asignaturaUpdate.activa} 
              onChange={e=> setAsignaturaUpdate({ ...asignaturaUpdate, activa: e.target.checked })} 
              name='activa' 
            />
          }
          label='Activa (disponible para contratar)'
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarAsignatura